import { RouteInfo } from './vertical-menu.metadata';


export const Lists_donhangck = { id: '0AF84C3A-B45C-4047-94DE-9D1D8D506200', path: '/chuc-nang/ban-hang-ck', title: 'Đơn hàng ck', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const Lists_hanghoa ={ id:'5F1DCF52-D28F-47E6-BFB5-141BF5AC22ED', path: '/danh-muc/hang-hoa', title: 'Hàng hóa', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const Lists_tudien = { id:'7C0B1595-63B1-4E29-908D-B0151B07B529', path: '/danh-muc/tu-dien', title: 'Từ điển', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const Lists_donvi ={ id:'FCA9923F-F783-4CA7-8116-16F0B07A3C3D', path: '/danh-muc/don-vi', title: 'Đơn vị', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const Lists_loai_hang = { id:'0FD19AB7-38C1-4C07-B571-E6A4C296F4BA', path: '/danh-muc/loai-hang-hoa', title: 'Loại hàng hóa', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLists = {
  id: '',
  title: 'Danh mục',
  path: '',
  icon: 'ft-align-left',
  class: 'has-sub',
  badge: '',
  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  isExternalLink: false,
  submenu: [Lists_hanghoa, Lists_loai_hang, Lists_donhangck, Lists_tudien]
};

export const MenuSystems_nguoidung ={ id:'0E72CDD8-2870-4D2C-9145-CB573E57C36F', path: '/he-thong/nguoi-dung', title: 'Người dùng', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuSystems_nhomnguoidung ={ id:'DB36BF37-1685-4EE3-B7AF-A89356985271', path: '/he-thong/vai-tro', title: 'Vai trò', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuSystems_nhatkytruycap ={ id:'888CFEB5-5728-475E-8044-D5CA6A9108B4', path: '/he-thong/nhat-ky', title: 'Chấm công', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuSystems = {
  id: '',
  title: 'Hệ thống',
  path: '',
  icon: 'ft-align-left',
  class: 'has-sub',
  badge: '',
  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  isExternalLink: false,
  submenu: [MenuSystems_nguoidung,MenuSystems_nhomnguoidung, MenuSystems_nhatkytruycap,]
};
export const MenuFunctions_flashoff ={ id:'16DEDA07-2EF9-421B-AF87-91182498CA0C', path: '/chuc-nang/flash-sale', title: 'Flash Sale', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuFunctions_banggia ={ id:'FC5F956B-81BF-4924-B90F-E42E120337BB', path: '/chuc-nang/bang-gia', title: 'Bảng giá', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuFunctions_dangky = { id: '24662B29-E1CB-4EF6-9636-22B510812741', path: '/chuc-nang/dang-ky-bien', title: 'Đăng ký xe', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_DonMart = { id: '10F4C060-8043-47B1-BD61-E94EB15E1BB3', path: '/chuc-nang/ban-mart', title: 'Đơn hàng', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuFunctions = {
  id: '',
  title: 'Rio Mart',
  path: '',
  icon: 'ft-layers',
  class: 'has-sub',
  badge: '',
  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  isExternalLink: false,
  submenu: [MenuFunctions_banggia, MenuFunctions_flashoff, MenuRioHome_DonMart]
};
 

export const MenuRioHome_DonHang = { id: 'FC5F956B-81BF-4924-B90F-E42E120337BB', path: '/chuc-nang/ban-hang', title: 'Đơn hàng', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_DichVu = { id: '24662B29-E1CB-4EF6-9636-22B510812741', path: '/danh-muc/dich-vu', title: 'Dịch vụ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BangGia = { id: '24662B29-E1CB-4EF6-9636-22B510812741', path: '/chuc-nang/bang-gia-dv', title: 'Bảng giá', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_DichVuGia = { id: '5C4CA38D-7CAC-4C61-900F-9065F465C21A', path: '/danh-muc/dich-vu-gia', title: 'Cài giá dịch vụ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_KhachHang = { id: '24662B29-E1CB-4EF6-9636-22B510812741', path: '/danh-muc/khach-hang', title: 'Khách hàng', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_LoaiDichVu = { id: '24662B29-E1CB-4EF6-9636-22B510812741', path: '/danh-muc/loai-dich-vu', title: 'Loại dịch vụ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_TheDichVu = { id: '9025E074-A1A3-44B0-9A14-09849E717A51', path: '/danh-muc/the-dich-vu', title: 'Thẻ dịch vụ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_LoaiThe = { id: '37E379EA-E970-407E-BBF9-1C444E1D71BC', path: '/danh-muc/loai-the', title: 'Loại thẻ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_Voucher = { id: '37E379EA-E970-407E-BBF9-1C444E1D71BC', path: '/danh-muc/voucher', title: 'Voucher', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_Push = { id: '37E379EA-E970-407E-BBF9-1C444E1D71BC', path: '/chuc-nang/push-noti', title: 'Thông báo cho App', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BC1 = { id: '37E379EA-E970-407E-BBF9-1C444E1D71BC', path: '/chuc-nang/bao-cao-gio-lam', title: 'Báo cáo giờ làm', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BC2 = { id: 'EC8D3835-454F-4D2B-AB12-7B700E41AF62', path: '/chuc-nang/bao-cao-gio-trong', title: 'Báo cáo giờ trống', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BC3 = { id: 'A65A2E64-6842-4DA5-8B0B-1B94A3AF4ABE', path: '/chuc-nang/bao-cao-doanh-thu', title: 'Báo cáo doanh thu', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BC4 = { id: '0EBB7C0F-8729-4E6D-8CC4-BC64140DC9DC', path: '/chuc-nang/bao-cao-doanh-thu-ngay', title: 'Doanh thu chi tiết', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuRioHome_BC5 = { id: '08F1C25B-C249-4175-B04F-3ADC03653D91', path: '/chuc-nang/bao-cao-dat-lai', title: 'Doanh thu đặt lại', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };



export const MenuRioHome = {
  id: '',
  title: 'Rio home',
  path: '',
  icon: 'fa fa-home',
  class: 'has-sub',
  badge: '',
  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  isExternalLink: false,
  submenu: [MenuRioHome_KhachHang, MenuRioHome_DonHang, MenuRioHome_DichVu,
    MenuRioHome_BangGia,
    MenuRioHome_DichVuGia,
    MenuRioHome_TheDichVu, MenuRioHome_LoaiDichVu, MenuRioHome_LoaiThe, MenuRioHome_Push
    , MenuRioHome_Voucher
    , MenuRioHome_BC1
    , MenuRioHome_BC2
    , MenuRioHome_BC3
    , MenuRioHome_BC4
    , MenuRioHome_BC5
  ]
};

export const MenuLuong_CauHinh = { id: '08B9EB7F-6D43-44DD-8BCC-473660BFA04F', path: '/danh-muc/cau-hinh-luong', title: 'Cấu hình lương', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong_KhoanLuong = { id: '91E60FEB-3F7E-4618-93FD-69D32570CF5C', path: '/danh-muc/khoan-luong', title: 'Khoản lương', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong_LoiPhat = { id: '592174F5-2D12-438A-AAED-B4CDAD630DDF', path: '/danh-muc/loi-phat', title: 'Danh sách phạt lỗi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong_TangCa = { id: '0E1BF01F-2410-4928-BD7B-6F184AE30D81', path: '/danh-muc/tang-ca', title: 'Danh sách tăng ca', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong_PCNgoaiKhu = { id: '0F5EFED9-2AAE-4E23-9167-7A150E2F3F5C', path: '/danh-muc/pc-ngoaikhu', title: 'Phụ cấp ngoại khu', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong_BangCong = { id: '55380B0E-29BD-4229-9233-5A809F5DFFB1', path: '/chuc-nang/bang-cong', title: 'Bảng công', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] };
export const MenuLuong = {
  id: '',
  title: 'Lương',
  path: '',
  icon: 'fa fa-inbox',
  class: 'has-sub',
  badge: '',
  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  isExternalLink: false,
  submenu: [
    MenuLuong_BangCong,
    MenuLuong_TangCa,
    MenuLuong_LoiPhat,
    MenuLuong_PCNgoaiKhu,
    MenuLuong_KhoanLuong,
    MenuLuong_CauHinh,

  ]
};
//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    id:'', path: '/page', title: 'Page', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  MenuRioHome,
  MenuLuong,
  MenuFunctions,
  MenuLists,
  MenuSystems,
];


export class BaoCao {
  // dùng chung để tải mẫu báo cáo (dùng cho chức năng tùy chỉnh báo cáo)
  public static readonly BC01_XuatMauBaoCao = 'C43BF77C-729B-4FB1-9E3C-B17FCF224BDA';
  // dùng để tải mẫu import (dùng cho chức năng nhập dữ liệu từ excel)
  public static readonly BC02_MauNhapTuExcel = '85F8063B-87A2-4CD9-849B-7E90DB5C9072';
  // dùng để xuất dữ liệu trên các lưới ra excel
  public static readonly BC03_XuatDanhSach = 'CBB9B028-4FE0-4518-8A4B-E5F16CACCAB6';
}
