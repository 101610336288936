
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import "rxjs/add/operator/map";

import "rxjs/add/operator/catch";


import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry } from 'rxjs-compat/operator/retry';

@Injectable()
export class Repository {

  private token: string = "";
  private username: string = "";
  private data: any;

  toaster: any;

  constructor(private http: HttpClient
   
  ) {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
  }



  public postPdf(url: string, data?: any): Observable<any> {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('Content-Type', 'application/json');

    return this.http.post( url, data, {
      headers: httpHeaders,
      observe: 'response',
      responseType: 'blob'
    }).pipe(catchError((err: HttpErrorResponse) => {
     
     
      if (err.status == 404) {
        
        return "L";
      }
     
      if (err.statusText == "Unknown Error") {
        //this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
      }
      if (err.status == 401) {
        location.href = '/#/login';

      }
      else {

        if (err.error instanceof Error) {
        } else {
        }
      }
      return null;
    }
   ));

  }

  public postRequest(url: string, data?: any): Observable<any> {
    // if (this.token == null || this.token == "") {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
    // }
    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('Content-Type', 'application/json');

    return this.http.post( url, data, {
      headers: httpHeaders,            
      responseType: 'json'
    }).pipe(catchError((err: HttpErrorResponse) => {
      //console.error('An error occurred:', err);
    
      //console.log(err);
      if (err.status == 404) {
        if (err.error.message == "Loi:There are not visible sheets on the file")
          alert("Không có dữ liệu để in!");
        else
          alert(err.error.message);
      }
      if (err.statusText == "Unknown Error") {
        //this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
      }
      if (err.status == 401) {
        location.href = '/#/login';

      }
      else {

        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          //console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
      }
      // ...optionally return a default fallback value so app can continue (pick one)
      // which could be a default value
      // return Observable.of<any>({my: "default value..."});
      // or simply an empty observable
      return null;
    }
    ));

  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser'); 
    //localStorage.clear();
  }

  public getCC(url: string)
    : Observable<any> {



    //if (this.token == null || this.token == "") {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
    //}

    //console.log(this.token);

    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('Content-Type', 'application/json');

    return this.http.get( url, {
      headers: httpHeaders,    
      responseType: 'json'
    }).pipe(catchError((err: HttpErrorResponse) => {
      console.error('An error occurred:', err);

      if (err.statusText == "Unknown Error") {
        //this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
      }
      if (err.status == 401) {
        location.href = '/#/login';
      }
      else {
        //this.toasterService.pop('error', null, err.messag);
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          //console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
      }
      // ...optionally return a default fallback value so app can continue (pick one)
      // which could be a default value
      // return Observable.of<any>({my: "default value..."});
      // or simply an empty observable
      return null;
    }
));

  }

  public getRequest(url: string, data?: any)
    : Observable<any> {



    //if (this.token == null || this.token == "") {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
    //}

    //console.log(this.token);

    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('Access-Control-Allow-Origin', '*')     
      .set('Content-Type', 'application/json')
      .set('x-api-key', '1f9aa821d08f4b2f877df527509b67c8');

    return this.http.get(url, {
      headers: httpHeaders,
      params: data,
      responseType: 'json'
    }).pipe(catchError((err: HttpErrorResponse) => {
      console.error('An error occurred:', err);
      
      if (err.statusText == "Unknown Error") {
        //this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
      }
      if (err.status == 401) {
        location.href = '/#/login';
      }
      else {
        //this.toasterService.pop('error', null, err.messag);
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          //console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
      }
      // ...optionally return a default fallback value so app can continue (pick one)
      // which could be a default value
      // return Observable.of<any>({my: "default value..."});
      // or simply an empty observable
      return null;
    }
));

  }
 public getRequestRR(url: string, data?: any)
    : Observable<any> {



    //if (this.token == null || this.token == "") {

    this.data = JSON.parse(localStorage.getItem('currentUser'));
    if (this.data != null) {
      this.token = this.data.access_token;

    }
    //}

    //console.log(this.token);

    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + this.token)
      .set('Content-Type', 'application/json');

    return this.http.get( url, {
      headers: httpHeaders,
      params: data,
      responseType: 'json'
    }).pipe(catchError((err: HttpErrorResponse) => {
      console.error('An error occurred:', err);
      
      if (err.statusText == "Unknown Error") {
        //this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
      }
      if (err.status == 401) {
        location.href = '/#/login';
      }
      else {
        //this.toasterService.pop('error', null, err.messag);
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          //console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
      }
      // ...optionally return a default fallback value so app can continue (pick one)
      // which could be a default value
      // return Observable.of<any>({my: "default value..."});
      // or simply an empty observable
      return null;
    }
));

  }

  public NgonNgu(text: string) {
    this.data = JSON.parse(localStorage.getItem('currentUser'));
    // khác 1 thì truy cập lại controller để lấy giá trị 
    if (this.data.ds_ngonngu != null && (this.data.ngon_ngu !== null ? this.data.ngon_ngu : "1") != "1") {
      var giatri = this.data.ds_ngonngu.filter(name => name.Ten.toLowerCase() === text.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].GiaTri == null || giatri[0].GiaTri == "") {
          this.postRequest('api/donvi/addorupdate_ngonngu', { Ten: text, Id: (this.data.ngon_ngu !== null ? this.data.ngon_ngu : "1") })
            .subscribe(response => {

            });
          return text;
        }
        else {
          return giatri[0].GiaTri;
        }
      }
      else {
        this.postRequest('api/donvi/addorupdate_ngonngu', { Ten: text, Id: (this.data.ngon_ngu !== null ? this.data.ngon_ngu : "1") })
          .subscribe(response => {
            return response;
          });
      }
    }
    else {
      this.postRequest('api/donvi/addorupdate_ngonngu', { Ten: text, Id: (this.data.ngon_ngu !== null ? this.data.ngon_ngu : "1") })
        .subscribe(response => {

        });
      return text;
    }

  }


}
@Injectable()
export class WindowRef {
  constructor() { }

  getNativeWindow() {
    return window;
  }
}
//@Injectable()
//export class LocalizeService {

//  private token: string = "";
//  private username: string = "";
//  private data: any;

//  toaster: any;

//  constructor(private http: HttpClient
//    , public toasterService: ToasterService
//  ) {

//    this.data = JSON.parse(localStorage.getItem('currentUser'));
//    if (this.data != null) {
//      this.token = this.data.access_token;

//    }
//  }


//  public Localize(url: string, data?: any): Observable<any> {


//    let httpHeaders = new HttpHeaders()
//      .set('authorization', 'Bearer ' + this.token)
//      .set('Content-Type', 'application/json');
//    if (this.data.Localize != null) {
//      var giatri = this.data.Localize.filter(name => name.text.toLowerCase() === data.text.toLowerCase());
//      if (giatri != null && giatri.length > 0) {
//        if (giatri[0].value == null || giatri[0].value == "") {
//          return data.text;
//        }
//        else {
//          //return this.http.post(Global.App_url + url, data, {
//          //  headers: httpHeaders,
//          //  observe: 'response',
//          //  responseType: 'blob'
//          //}).catch((err: HttpErrorResponse) => {
//          //  $('#loading').hide();
//          //  if (err.status == 404) {
//          //    return "L";
//          //  }
//          //  if (err.statusText == "Unknown Error") {
//          //    this.toasterService.pop('error', null, 'Không kết nối được với máy chủ');
//          //  }
//          //  if (err.status == 401) {
//          //    location.href = '/#/login';

//          //  }
//          //  else {

//          //    if (err.error instanceof Error) {
//          //    } else {
//          //    }
//          //  }
//          //  return null;
//          //});
//          return data.text;
//        }
//      }
//    }
//    else {
//      return data.text;
//    }


//  }

//}
