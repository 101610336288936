import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
 {
     path: 'danh-muc',
     loadChildren: () => import('../../danhmuc/dm-pages.module').then(m => m.DmPagesModule)
  },
 {
     path: 'he-thong',
     loadChildren: () => import('../../hethong/ht-pages.module').then(m => m.HtPagesModule)
  },
 {
     path: 'chuc-nang',
     loadChildren: () => import('../../chucnang/hs-pages.module').then(m => m.HsPagesModule)
  }
];
