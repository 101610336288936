<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-3">Mã</label>
      <div class="col-9">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="Nếu không nhập hệ thống tự sinh" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">

      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Tên</label>
      <div class="col-9">
        <input type="text" (keydown)="keyTenPress($event)" tabIndex="2" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
      </div>
    </div>

    <div class="row form-group" *ngIf="!bnhomctmt">
      <label class="control-label col-3 ng-binding">Cấp trên</label>
      <div class="col-9">
        <ejs-combobox id='games' #local
                      [dataSource]='inputparents'
                      [autofill]='true'
                      [fields]='localFields'
                      [placeholder]='"Chọn cấp trên"'
                      [popupHeight]='"250px"'
                      [(ngModel)]="doituongSelect.Parent"
                      ></ejs-combobox>

       

      </div>

    </div>

    <div class="row form-group">
      <label class="control-label col-3 ng-binding">{{tieudectmt}}</label>
      <div class="col-9">
        <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary">Lưu lại</button>
  </div>

</div>


